export default {
  close: "Close",
  successTitle: "Success",
  errorTitle: "Error",
  important: "Important",
  submit: "Submit",
  contactAdmin: "Contact Admin",
  cancle: "Cancel",
  copy: "Copy",
  menu: {
    cashCard: "Cash Card    ",
    idPass: "Top-up ID - Pass",
    toupAuto: "Top-up Auto",
  },
  home: "Home",
  topup_id_pass: "Top-Up ID/PASS",
  coin_topup: "Coin Top-Up",
  news_promotions: "News and Promotions",
  buy_sell_items: "Buy/Sell Items",
  contact_us: "Contact Us",
  profile: "Profile",
  sign_in: "Sign-In",
  navbar: {
    signUp: "Sign-Up",
    signIn: "Sign-In",
    dashboard: "Dashboard",
    topup: "Topup",
  },
  homepage: {
    choose: "choose",
    more: "Show More",
    all: "All",
    new: "New",
    hot: "Hot",
    searchGame: "Search..",
    topupGame: "Top-Up Game",
    topupCoin: "Top-Up Coin",
    policy: "Policy",
    condition: "Terms",
    notFoundGame: "Game not Found",
  },
  faq: {
    title: "Questions Q&A",
    questions: [
      {
        question: "How to register ?",
        answer: `You can easily register by clicking on the sign-up button or 
        <span class="text-danger fw-bold cursor-pointer" @click="$router.push('/signup')">"click here"</span>. 
        You can register using both Google account and Line.`,
      },
      {
        question: "Is it an automatic top-up ?",
        answer:
          "The goldenbug top-up website has an automatic top-up system that can serve a large number of customers simultaneously.",
      },
      {
        question: "Is the service open 24 hours ?",
        answer:
          "The Goldenbug top-up website is open 24 hours a day without closing, ensuring continuous enjoyment for gamers.",
      },
      {
        question: "Does it take long to top up the website ?",
        answer:
          "Usually, topping up (credit) on the website takes about 0.30-01.00 minutes, which is very fast.",
      },
      {
        question: "How to contact customer service ?",
        answer: `Customers can contact customer service via Line Office by adding a friend at @710oclzg or 
        <a class="text-danger fw-bold" href="https://lin.ee/N0xyBar" target="_blank">"click here"</a>.`,
      },
    ],
  },
  seo: {
    title: "Goldenbug 24-Hour Game Top-Up",
    subtitle: "Goldenbug offers 24-hour online game UID top-up service.",
    content: "Goldenbug 24-hour game top-up",
    details:
      "with an automatic UID top-up system, fast service, and a customer service team available 24 hours a day. We offer game top-up services and sell gift cards such as Garena cards,",
    link: "razer gold gift card",
    footer:
      "Game Indy, and many more. We also have a support team available to assist users 24/7.",
  },
  coinTopup: {
    signup: "Sign up",
    amount: "Specify the amount",
    amountPayment: "Specify the amount to top up",
    amountClick: "Specify the amount, click here",
    minimunAmount: "Specify the amount, minimum 50",
    recommend: "Recommended",
    hot: "Best Seller",
    payment: "Choose a payment method",
    bank: "Bank",
    wallet: "True Wallet",
    membership:
      "Sign up for membership to receive many special privileges, click here",
    proForYou: "Promotion for you",
    notPro: "No promotions found for you",
    view: "View details",
    packageDetail: "Package details",
    conditionPro1:
      "Condition: Can be claimed until the admin closes this promotion",
    conditionPro2: "Condition: This promotion can only be claimed once",
    conditionPro3:
      "Condition: Limited promotion, can be claimed until the promotion runs out",
    conditionPro4:
      "Condition: This promotion is for users who have never topped up with Goldenbug",
    conditionPro5:
      "Condition: This promotion is for users who have previously topped up credits with us",
    conditionPro6: "Condition: This promotion is valid until",
    conditionPro7: "(Not counting previous top-ups through promotions)",
    expirePro: "Or until the promotion runs out",
    count: "times",
    bath: "baht",
    into: "until",
    price: "Price",
    bonus: "Bonus",
    usePro: "Redeem",
    tran: "Amount to transfer",
    tranAttach: "Transfer / Attach slip",
    copy: "Copy",
    readyCopy: "Amount to transfer copied",
    bankCopy: "Account number copied",
    attach: "Attach slip",
    deleteFile: "Delete image",
    submitForm: "Confirm transaction",
    closeCoine: "The credit top-up system is temporarily disabled.",
    whyGolden: "Why top up games with GoldenBug",
    title1: "Instant Top-Up, Fast Money Transfer!",
    desc1:
      "Auto top-up system within 1-3 minutes. Quick and convenient, no long waiting times.",
    title2: "Top up anytime, anywhere",
    desc2: "24-hour service with admin support available around the clock.",
    title3: "Convenient and Secure",
    desc3:
      "Top up via UID without needing to provide User and Password. Safe and worry-free.",
    title4: "100% Confidence Guaranteed",
    desc4: "Service provided by a company with stability and reliability.",
    history: "Credit top-up history",
    ref: "Reference code",
    tablePay: "Amount",
    timePay: "Transaction time",
    remark: "Remark",
    status: "Status",
    pending: "Pending",
    inProgress: "In Progress",
    success: "Success",
    fail: "Fail",
    chooseSlip: "Choose a slip to upload",
    slipAgain: "Choose a new image",
    slipTitleError: "Unable to upload the image",
    slipMessageError: "The image file must not exceed 2 MB",
    confirmTitle: "Confirm credit top-up?",
    confirmText:
      "Once you submit, credits will be added within 15 minutes. If it takes longer, please contact admin.",
    confirmButtonText: "Confirm Credit Top-up",
    validateFormTopup: "Please specify the amount of credits to top-up",
    validateFormSlip: "Please attach a new transfer slip",
    validateFormSlipUrl: "Please attach the transfer slip as proof of payment",
    validateFormPackage: "Please select a package to top-up",
    topupIsNaN: "Please enter the top-up amount as a number",
    topUpMinimun: "Specify a minimum amount of 50",
    topUpMaximun:
      "If you wish to top-up more than 10 million credits, please contact us",
    successMessage: "Top-up request submitted, processing...",
    validSignup: "Please sign in",
    rolePackage: "Once a package is selected, the amount cannot be specified",
  },
  checkOrder: {
    detailHeader: "Top-up Game Details",
    informationUserText: "User Information",
    informationGameText: "Game Information",
    nameGame: "Game Name",
    idGame: "Player ID",
    serverGame: "Server",
    list: "Items",
    amount: "Amount",
    price: "Price",
    bath: "Baht",
    total: "Total",
    awaitText: "*** Please wait until the display window appears...",
    thankyouText:
      "Thank you for trusting GOLDENBUG DEVELOPMENT CO., LTD. for your top-up service.",
    undoWeb: "Return to the homepage",
    sendAgain: "Try again",
    recheck: "Check status",
    topupSuccess: "Top-up successful",
    topupError: "Your transaction is still incorrect...",
    contactAdmin: "Please contact admin",
    timeout: "The waiting time for the balance update has exceeded the limit",
    checkOrderMessage: "Checking information... Click to check status",
  },
  signIn: {
    signin: "Sign In",
    username: "Username",
    password: "Password",
    remember: "Remember Me",
    login: "Login",
    rePassword: "Forgot Password",
    signup: "Sign Up",
    loginLine: "Sign In with LINE",
    loginGoogle: "Sign In with Google",
    rePassword2: "Forgot Password",
  },
  signUp: {
    signup: "Sign Up",
    username: "Username",
    password: "Password",
    fullname: "Full Name",
    phone: "Phone Number",
    email: "E-mail",
    signupLine: "Sign Up with LINE",
    signupGoogle: "Sign Up with Google",
  },
  sideNav: {
    historyOrder: "Order History",
    historyTopup: "Top-up History",
    redeem: "Redeem",
    dashboard: "Dashboard",
    logout: "Log-out",
  },
  historyOrder: {
    from: "From date",
    to: "To date",
    status: "Status",
    search: "Search",
    refresh: "Refresh",
    refresh1: "Refresh Every",
    second: "Second",
    refresh30: "Refresh Every 30 Seconds",
    refresh15: "Refresh Every 15 Seconds",
    refresh10: "Refresh Every 10 Seconds",
    refresh5: "Refresh Every 5 Seconds",
    total: "Total",
    pending: "Pending",
    inProcess: "In Process",
    error: "Error",
    success: "Success",
    overque: "Over Queue",
    noOrder: "Order Number",
    contactAdmin: "Contact Admin",
    information: "Information",
    alertMessage:
      "<span class='text-decoration-underline'>Please wait for processing </span>Our staff will contact you via the Line provided, or you can check the progress via Line <a href='https://lin.ee/N0xyBar' target='_blank' class='fw-bold text-danger'>click here</a>",
    notFoundOrder: "Order not found",
    closeWindow: "Close window",
    copySuccess: "Copied",
  },
  giftCode: {
    redeemTitle: "Redeem Code",
    description:
      "The gift code serves as cash to top up your account on <a href='https://termgame.goldenbug.co/' class='text-danger'>termgame.goldenbug.co</a> for game top-ups and purchasing gift cards within the site.",
    redeem: "Redeem",
    invalidCode: "Invalid coupon code",
    enterCode: "Please enter a coupon code",
    invalidFormat: "Invalid coupon code format",
    confirmTitle: "Confirm Coupon Redemption",
    confirmText: "Do you want to redeem this coupon?",
    confirmButton: "Confirm",
    cancelButton: "Cancel",
    successTitle: "Coupon Valid",
    successText: "Credit has been added successfully",
    errorTitle: "An Error Occurred",
    errorText: "Unable to redeem coupon",
    placeholder: "Enter gift code here...",
  },
  dashboard: {
    update: "Update Profile Information",
    changePassword: "Change Password",
    newPassword: "New Password",
    confirmPassword: "Confirm New Password",
  },
  passwordInstructions: {
    title: "Password Setup Instructions",
    subtitle: "Please follow our guidelines to protect your information:",
    rules: [
      "Use at least 1 special character",
      "Password length should be at least 8 characters",
      "Password must contain at least 1 number",
      "Change your password regularly",
    ],
    confirmButton: "Confirm Password Change",
  },
  accountDeletion: {
    title: "Request to Delete User Account",
    description:
      "If you wish to request the deletion of your personal and account-related information, please confirm the action. The system will remove your data within 30 business days.",
  },
  gameSingle: {
    index: "Home",
    topup: "Top Up",
    writeForm: "Form",
    chooseServer: "Choose Server",
    choosePackeage: "Choose Package",
    seller: "Best Seller",
    promotion: "Promotion",
    hot: "Limited Quantity",
    notFoundPackage: "No package available",
    selectedPackage: "Selected Package",
    item: "Item",
    total: "Total",
    undo: "Undo",
    uploadImg: "Upload Image",
    clickImg: "Click to select file",
    upload: "Upload",
    detailPack: "Package Details to Top Up",
    priceInGame: "In-game Package Price (USD)",
    amountPack: "Amount to Top Up",
    description: "Description (Additional)",
    exDescription:
      "Please provide additional description here (e.g., Top up image 1 with 3 packages at 100 USD in-game price)",
    price: "Price",
    amount: "Amount",
    inclound: "Including",
    agree: "I understand and accept",
    condition: "Service Terms",
    roles: "Please read and accept the service terms",
    submit: "Confirm Payment",
    notPromotion: "You have no available promotions",
    thisPromotion: "Your Promotion",
    rolesPromotion:
      "Conditions: To use the promotion, top-up must be done through credit only!!",
    package: "Please select the package you want",
    promotionTitle: "Top up to Goldenbug wallet is more worthwhile",
    promotionDesc:
      "Get an additional <span class='text-danger'>10%</span> credit when topping up through Goldenbug wallet",
    pricePay: "Amount to Pay",
    submitPay: "Confirm Payment",
    sendForm: "Send Information to Admin",
    rolesLogin: "Log in before placing an order",
    validSlip: "Please attach the payment slip before confirming the order",
    dataOrder: "Order Summary",
    imgOrder: "Please attach the image you want to top up",
    submitOrder: "Confirm Purchase?",
    warningText:
      "Once the information is submitted, it cannot be edited.\nIf incorrect information is entered and the system has already processed the payment, customers cannot request a refund in any case.",
    confirmButton: "Confirm Order",
    warningText2:
      "Once the information is submitted, it cannot be edited.\nIf you enter incorrect information, please notify the admin via the Official Line. Upon confirmation, you will be redirected to the processing page to continue.",
    validPackage: "Please select the package you want to purchase",
    rolesImg: "You can upload up to 3 images",
    messageErrorImg: "An error occurred while uploading the image",
  },
  orderHold: {
    title: "Orders in Progress",
    notHold: "No orders in progress",
  },
};
