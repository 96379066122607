<template lang="">
    <div>
        <div class="modal-dialog modal-dialog-centered">
            <div class="row modal-con">
                <button
                        type="button"
                        class="btn-close text-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                    </button>
                        <div class="col-md-12">
                            <!-- <img class="img-fluid mx-auto rounded w-100" :src="bannerUrl" alt=""> -->
                            <img class="img-fluid mx-auto rounded w-100" src="../assets/img/badge/เติมเกม24ชั่วโมง.jpg" alt="">
                        </div>
            </div>
        </div>
        <div id="jkanban-info-modal-backdrop" class="fixed inset-0 z-40 hidden bg-black opacity-50" ></div> 
    </div>
</template>
<script>
export default {
  name: 'ModalDialog',
  props: {
    title: {
      type: String,
      required: true
    },
    bannerUrl: {
      type: String,
      required: true
    },
    redirectUrl: {
      type: String,
      default: ''
    },
    altText: {
      type: String,
      default: 'Banner Image'
    }
  },
  mounted() {
    // เพิ่ม event listener เมื่อ Modal ถูกซ่อน
    const modalElement = document.querySelector('.modal');
    modalElement.addEventListener('hidden.bs.modal', this.saveToLocalStorage);
  },
  // created() {
  //   console.log('Props received:', this.$props.bannerUrl)
  // },
  methods: {
    saveToLocalStorage() {
      // ทำการบันทึกข้อมูลลงใน localStorage
      const currentTime = new Date().getTime();
      // console.log('currentTime: ', currentTime);
      localStorage.setItem('modalData', currentTime);
    }
  }
}
</script>
<style scoped>
    .modal-con{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 65%;
    }
    .btn-close {
        position: absolute;
        right: 0;
        top: -15px;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }
    @media (max-width: 768px) {
        .modal-con{
            width: 90%;
        }
    }
</style>
