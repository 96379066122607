<template>
  <navbar :is-blur="true" :dark-mode="true" />
    <div class="container px-0" style="margin-top: 9rem;">
      <div class="row blur-dark-fade p-4 mb-4 hero-page">
        <div class="col-md-7 col-12" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="300">
          <MainSliderHome class="w-100" :banners="this.getSetting.page?.home_page?.banners" />

        </div>
        <div class="col-md-5 col-12 position-relative" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">
          <div class="ms-4">
            <h5 class="text-white">ข่าวสารและโปรโมชั่น</h5>
            <div class="row" v-if="getArticle && getArticle.length">
              <div class="col-12 mb-3" v-for="(article, index) in limitedArticles" :key="index">
                <div class="row article-con">
                  <div class="col-4 d-flex justify-content-center align-items-center">
                    <!-- <img class="w-60 h-auto img-fluid object-fit-contain" src="https://via.placeholder.com/150" alt=""> -->
                    <img class="w-60 h-auto img-fluid object-fit-contain" :src="article.cover_url" alt="">
                  </div>
                  <div class="col-8 d-flex flex-column justify-content-center">
                    <h6 class="text-decoration-underline text-white">{{ article.subject }}</h6>
                    <p style="font-size:12px" class="text-white">{{ article.short_description }}</p>
                  </div>
                </div>
              </div>
          </div>
              <button class="btn btn-danger mb-0 p-2 position-absolute end-0 bottom-0" style="font-size: 10px;">อ่านเพิ่มเติม</button>
          </div>
        </div>
      </div>

      <div v-if="cardFilterNormal.length > 0" data-aos="zoom-in" data-aos-duration="800" data-aos-delay="100">
        <div class="row">
          <div class="d-flex justify-content-between">
            <h4 class="text-white">บัตรเงินสด</h4>
            <button class="btn btn-danger p-2" style="font-size: 10px;">แสดงทั้งหมด</button>
          </div>
        </div>
  
        <div class="row blur-dark-fade p-4 mb-4">
          <div class="container" v-if="cardFilterNormal && cardFilterNormal.length">
            <Carousel
              ref="myCarousel"
              v-model="currentSlide"
              v-bind="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="(card, index) in cardFilterNormal" :key="index">
                <div class="slide-con w-100 px-3" style="height: 250px;">
                  <div
                    class="card card-box shadow zoom-product mx-auto"
                    style="height: 150px; width:150px;"
                    data-scroll
                    href="#"
                    @click="goToBuy(card._id)"
                  >
                    <div class="card-gradient h-100">
                      <div class="row g-0 h-100" style="width: 100%">
                        <div class="col-12 col-md-12 h-100">
                          <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" class="img-fluid rounded-start w-100 h-100 object-fit-cover" alt="" /> -->
                          <img
                            v-lazy="card.icon_url"
                            class="img-fluid rounded-start w-100 h-100 object-fit-cover"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12 ms-3 w-100 d-flex flex-column justify-content-center h-100">
                      <h5 class="card-title">{{ card.name }}</h5>
                      <p class="card-text">{{ card.description }}</p>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>

      <div v-if="gameFilterNormal.length > 0" data-aos="zoom-in" data-aos-duration="800" data-aos-delay="100">
        <div class="row">
          <div class="d-flex justify-content-between">
            <h4 class="text-white">เติมเกม ID - PASS</h4>
            <button class="btn btn-danger p-2" style="font-size: 10px;">แสดงทั้งหมด</button>
          </div>
        </div>
  
        <div class="row blur-dark-fade p-4 mb-4">
          <div class="container" v-if="gameFilterNormal && gameFilterNormal.length">
            <Carousel
              ref="myCarousel"
              v-model="currentSlide"
              v-bind="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="(game, index) in gameFilterNormal" :key="index">
                <div class="slide-con w-100 px-3" style="height: 250px;">
                  <div
                    class="card card-box shadow zoom-product mx-auto"
                    style="height: 150px; width:150px;"
                    data-scroll
                    href="#"
                    @click="goToBuy(game._id)"
                  >
                    <div class="card-gradient h-100">
                      <div class="row g-0 h-100" style="width: 100%">
                        <div class="col-12 col-md-12 h-100">
                          <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" class="img-fluid rounded-start w-100 h-100 object-fit-cover" alt="" /> -->
                          <img
                            v-lazy="game.icon_url"
                            class="img-fluid rounded-start w-100 h-100 object-fit-cover"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12 ms-3 w-100 d-flex flex-column justify-content-center h-100">
                      <h5 class="card-title">{{ game.name }}</h5>
                      <p class="card-text">{{ game.description }}</p>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>

      <div v-if="gamesFilterAuto.length > 0" data-aos="zoom-in" data-aos-duration="800" data-aos-delay="100">
        <div class="row">
          <div class="d-flex justify-content-between">
            <h4 class="text-white">เติมเกมอัตโนมัติ</h4>
            <button class="btn btn-danger p-2" style="font-size: 10px;">แสดงทั้งหมด</button>
          </div>
        </div>
  
        <div class="row blur-dark-fade p-4 mb-4">
          <div class="container" v-if="gamesFilterAuto && gamesFilterAuto.length">
            <Carousel
              ref="myCarousel"
              v-model="currentSlide"
              v-bind="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="(card, index) in gamesFilterAuto" :key="index">
                <div class="slide-con w-100 px-3" style="height: 250px;">
                  <div
                    class="card card-box shadow zoom-product mx-auto"
                    style="height: 150px; width:150px;"
                    data-scroll
                    href="#"
                    @click="goToBuy(card._id)"
                  >
                    <div class="card-gradient h-100">
                      <div class="row g-0 h-100" style="width: 100%">
                        <div class="col-12 col-md-12 h-100">
                          <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" class="img-fluid rounded-start w-100 h-100 object-fit-cover" alt="" /> -->
                          <img
                            v-lazy="card.icon_url"
                            class="img-fluid rounded-start w-100 h-100 object-fit-cover"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12 ms-3 w-100 d-flex flex-column justify-content-center h-100">
                      <h5 class="card-title">{{ card.name }}</h5>
                      <p class="card-text">{{ card.description }}</p>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>

      <div class="mb-5" v-if="gamesFilterAuto.length > 0" data-aos="zoom-in" data-aos-duration="800" data-aos-delay="100">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="card bg-white shadow-lg rounded-lg overflow-hidden mb-4">
              <div class="card-header bg-primary text-white py-3">
                <h4 class="mb-0 font-weight-bold text-white">Review</h4>
              </div>
              <div class="card-body p-4" style="height: 670px; overflow-y: auto;">
                <div
                  class="fb-comments"
                  data-href="https://termgame.goldenbug.co/"
                  data-width="100%"
                  data-numposts="5"
                  data-order-by="reverse_time"
                ></div>
              </div>
            </div>
          </div>
            
          <div class="col-md-6 col-12" data-aos="fade-left" data-aos-duration="800" data-aos-delay="100">
            <div class="row mb-3">
              <div class="d-flex flex-column align-items-center mb-3">
                <div class="mb-3" style="width: 80px; height: 80px;">
                  <img
                    src="../../../public/logo.png"
                    class="img-fluid w-100 h-100 m-auto"
                    alt="เติมเกม"
                  />
                </div>
                <div class="px-2">
                  <h1 class="seo_title text-center">{{ $t('seo.title') }}</h1>
                  <h2 class="fs-6 mb-3 text-center" style="color: white">
                    {{ $t('seo.subtitle') }}
                  </h2>
                  <p style="color: white; font-size: 0.8rem">
                    <span
                      class="text-danger fw-bold cursor-pointer"
                      @click="$router.push('#')"
                    >
                      {{ $t('seo.content') }}
                    </span>
                    {{ $t('seo.details') }}
                    <a
                      href="https://gold.razer.com/us/en"
                      class="text-danger fw-bold"
                      target="_blank"
                    >
                      {{ $t('seo.link') }}
                    </a>
                    {{ $t('seo.footer') }}
                  </p>
                </div>
              </div>
              <div>
                <h2 class="text-danger fs-4">{{ $t('faq.title') }}</h2>
                <div>
                  <!-- Question 1 -->
                  <div>
                    <div
                      class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 py-2"
                      @click="toggleP_1"
                    >
                      <h3 class="fs-6 text-white mb-0 fw-normal">{{ $t('faq.questions.0.question') }}</h3>
                      <i
                        class="fas text-white me-4"
                        :class="showP_1 ? 'fa-chevron-up' : 'fa-chevron-down'"
                      ></i>
                    </div>
                    <transition name="slide-fade">
                      <p
                        v-if="showP_1"
                        class="text-white text-light ms-1 animated-h2 mt-3 w-90"
                        style="font-size: 14px;"
                        v-html="$t('faq.questions.0.answer')"
                      ></p>
                    </transition>
                  </div>
        
                  <!-- Question 2 -->
                  <div>
                    <div
                      class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 py-2"
                      @click="toggleP_2"
                    >
                      <h3 class="fs-6 text-white mb-0 fw-normal">{{ $t('faq.questions.1.question') }}</h3>
                      <i
                        class="fas text-white me-4"
                        :class="showP_2 ? 'fa-chevron-up' : 'fa-chevron-down'"
                      ></i>
                    </div>
                    <transition name="slide-fade">
                      <p
                        v-if="showP_2"
                        class="text-white text-light ms-1 animated-h2 mt-3 w-90"
                        style="font-size: 14px;"
                        v-html="$t('faq.questions.1.answer')"
                      ></p>
                    </transition>
                  </div>
        
                  <!-- Question 3 -->
                  <div>
                    <div
                      class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 py-2"
                      @click="toggleP_3"
                    >
                      <h3 class="fs-6 text-white mb-0 fw-normal">{{ $t('faq.questions.2.question') }}</h3>
                      <i
                        class="fas text-white me-4"
                        :class="showP_3 ? 'fa-chevron-up' : 'fa-chevron-down'"
                      ></i>
                    </div>
                    <transition name="slide-fade">
                      <p
                        v-if="showP_3"
                        class="text-white text-light ms-1 animated-h2 mt-3 w-90"
                        style="font-size: 14px;"
                        v-html="$t('faq.questions.2.answer')"
                      ></p>
                    </transition>
                  </div>
        
                  <!-- Question 4 -->
                  <div>
                    <div
                      class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 py-2"
                      @click="toggleP_4"
                    >
                      <h3 class="fs-6 text-white mb-0 fw-normal">{{ $t('faq.questions.3.question') }}</h3>
                      <i
                        class="fas text-white me-4"
                        :class="showP_4 ? 'fa-chevron-up' : 'fa-chevron-down'"
                      ></i>
                    </div>
                    <transition name="slide-fade">
                      <p
                        v-if="showP_4"
                        class="text-white text-light ms-1 animated-h2 mt-3 w-90"
                        style="font-size: 14px;"
                        v-html="$t('faq.questions.3.answer')"
                      ></p>
                    </transition>
                  </div>
        
                  <!-- Question 5 -->
                  <div>
                    <div
                      class="d-flex justify-content-between align-items-center cursor-pointer shadow-md rounded-lg overflow-hidden mb-2 py-2"
                      @click="toggleP_5"
                    >
                      <h3 class="fs-6 text-white mb-0 fw-normal">{{ $t('faq.questions.4.question') }}</h3>
                      <i
                        class="fas text-white me-4"
                        :class="showP_5 ? 'fa-chevron-up' : 'fa-chevron-down'"
                      ></i>
                    </div>
                    <transition name="slide-fade">
                      <p
                        v-if="showP_5"
                        class="text-white text-light ms-1 animated-h2 mt-3 w-90"
                        style="font-size: 14px;"
                        v-html="$t('faq.questions.4.answer')"
                      ></p>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
            <hr style="background-color: white; height:1px">
            <div class="row mb-3">
              <div class="col-6">
                <h5 class="text-danger">Facebook Fanpage</h5>
                <div class="fb-page" data-href="https://www.facebook.com/GoldenbugGameShopping" data-tabs="timeline" data-width="300" data-height="100" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/GoldenbugGameShopping" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/GoldenbugGameShopping">Goldenbug เติมเกม 24 ชั่วโมง</a></blockquote></div>
              </div>
              <div class="col-6">
                <h5 class="text-danger">Contact</h5>
                <p class="text-white">Line: @GoldenBug</p>
                <p class="text-white">เปิดปริการ 24 ชั่วโมง</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <app-footer />
    <ModalDialog
          id="jkanban-info-modal"
          class="modal fade"
          style="display: none"
          tabindex="-1"
          role="dialog"
          :bannerUrl="getWelcomeBanner.banner_url"
          v-if="getWelcomeBanner && getWelcomeBanner.banner_url"
          />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import MainSliderHome from "@/views/pages/components/MainSliderHome.vue";
import AOS from "aos";
import "aos/dist/aos.css";

import { mapMutations, mapActions, mapGetters } from "vuex";
import ModalDialog from "../../components/Modal.vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";


export default {
  name: "SigninBasic",
  components: {
    Navbar,
    AppFooter,
    MainSliderHome,
    ModalDialog,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      verify: window.localStorage.getItem("verify") || false,

      currentSlide: 0,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        wrapAround: false,
      },
      breakpoints: {
      300: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        400: {
          itemsToShow: 1.5,
          snapAlign: "center",
        },
        500: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        600: {
          itemsToShow: 2.5,
          snapAlign: "center",
        },
        700: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        900: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1000: {
          itemsToShow: 4.5,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
        1118: {
          itemsToShow: 6,
          snapAlign: "start",
        },
      },
      showP_1: false,
      showP_2: false,
      showP_3: false,
      showP_4: false,
      showP_5: false,
      isMobile: window.innerWidth <= 768, // Check if the device is mobile
    };
  },
  async created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    await this.fetchGames();

    let param = {
      page_index: 1,
      limit_list_per_page: 9
    }
    this.fetchArticle(param);
  },
  mounted() {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      disable: "mobile"
    });

    if (!this.checkLogin) {
      this.$store.dispatch("logOut");
    }

    if (this.getWelcomeBanner.banner_url) {
      const now = new Date().getTime();
      const oneHourInMs = 1000;
      const lastModalClosedTime = localStorage.getItem("modalData");
      if (!lastModalClosedTime || now - parseInt(lastModalClosedTime) >= oneHourInMs) {
        this.showModal();
      }
    }

    const checkFBReady = () => {
      if (window.FB && window.FB.getLoginStatus) {
        window.FB.XFBML.parse();
      } else {
        setTimeout(checkFBReady, 100); // Retry every 100ms if FB SDK is not available
      }
    };

    checkFBReady();

    window.addEventListener("resize", this.handleResize);
  },
  async beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    await window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters([
      "games",
      "gamesTopupAuto",
      "gamesTopupNormal",
      "homeBanner",
      "getSetting",
      "isLoggedIn",
      "getArticle"
    ]),
    limitedArticles() {
      return this.getArticle.slice(0, 2);
    },
    cardFilterNormal() {
      let filteredCards = this.gamesTopupAuto.filter(
        (game) => game.product_type == 2
      );
      if (this.postStatus) {
        return filteredCards.filter((game) => {
          return game.post_status == this.postStatus;
        });
      }
      // console.log('filteredCards: ', filteredCards);
      return filteredCards;
    },
    gameFilterNormal() {
      let filteredGames = this.gamesTopupNormal.filter(
        (game) => game.product_type == 1
      );
      if (this.searchInputNormal) {
        if (this.postStatusNormal) {
          return filteredGames.filter((game) => {
            return (
              game.name
                .toLowerCase()
                .includes(this.searchInputNormal.toLowerCase()) &&
              game.post_status == this.postStatusNormal
            );
          });
        }
        return filteredGames.filter((game) => {
          return game.name
            .toLowerCase()
            .includes(this.searchInputNormal.toLowerCase());
        });
      }
      if (this.postStatusNormal) {
        return filteredGames.filter((game) => {
          return game.post_status == this.postStatusNormal;
        });
      }
      return filteredGames;
    },
    gamesFilterAuto() {
      let filteredGames = this.gamesTopupAuto.filter(
        (game) => game.product_type == 1
      );
      console.log('filteredGames: ', filteredGames);
      if (this.searchInput) {
        if (this.postStatus) {
          return filteredGames.filter((game) => {
            return (
              game.name
                .toLowerCase()
                .includes(this.searchInput.toLowerCase()) &&
              game.post_status == this.postStatus
            );
          });
        }
        return filteredGames.filter((game) => {
          return game.name
            .toLowerCase()
            .includes(this.searchInput.toLowerCase());
        });
      }
      if (this.postStatus) {
        return filteredGames.filter((game) => {
          return game.post_status == this.postStatus;
        });
      }
      return filteredGames;
    },
    getWelcomeBanner() {
      // console.log('this.getSetting?: ', this.getSetting);
      return this.getSetting?.page?.home_page.welcome_banner;
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["fetchGames", "fetchBanners",'fetchArticle']),
    goToBuy(param) {
      // console.log(param);
      return this.$router.push({
        name: "GameTopUpSingle",
        params: { game_id: param },
      });
    },
    checkLogin() {
      return typeof this.getMemberProfile !== "undefined";
    },
    async showModal() {
      const bootstrap = this.$store.state.defaultStore.bootstrap;

      let jkanbanInfoModal = document.getElementById("jkanban-info-modal");

      var myModal = new bootstrap.Modal(jkanbanInfoModal, {
        show: true,
      });
      myModal.show();
    },

    hideModal() {
      document.querySelector("#jkanban-info-modal").classList.remove("show");
      document.querySelector("body").classList.remove("modal-open");
      document.querySelector("body").style = "";
      document.querySelector(".modal-backdrop").remove();
    },
    toggleP_1() {
      this.showP_1 = !this.showP_1;
    },
    toggleP_2() {
      this.showP_2 = !this.showP_2;
    },
    toggleP_3() {
      this.showP_3 = !this.showP_3;
    },
    toggleP_4() {
      this.showP_4 = !this.showP_4;
    },
    toggleP_5() {
      this.showP_5 = !this.showP_5;
    },
    async handleResize() {
      this.isMobile = await window.innerWidth <= 768;
    },
  },
}
</script>

<style lang="scss">
.article-con{
 height: 120px;
}
.zoom-product {
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease;
}

.zoom-product:before,
.zoom-product:after {
  content: "";
  position: absolute;
  top: -50%;
  left: -100%;
  width: 60%;
  height: 200%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(25deg);
  z-index: 1;
}

.zoom-product:before {
  transition: all 1.2s ease;
}

.zoom-product:after {
  transition: all 1.2s ease;
  transition-delay: 0.4s;
}

.zoom-product:hover:before,
.zoom-product:hover:after {
  left: 130%;
}

.zoom-product:hover {
  transform: scale(1.03);
}

.zoom-product .btn {
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.zoom-product:hover .btn {
  background-color: #dc3545;
  color: white;
}
.card-box {
  transition: all 0.3s ease;
  overflow: hidden;
  border: none;
  background-color: #2c2c2c !important;
  cursor: pointer;
}

.card-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.card-gradient {
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  border-radius: 10px;
}

.content-con {
  background-color: red;
  height: 100%;
  text-align: start;
}
.card-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #fff;
  font-size: 1rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 15px;
}

.card-text {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 20px;
  max-height: 70px;
}

.card-box img {
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.card-box:hover img {
  transform: scale(1.05);
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.carousel__prev,
.carousel__next {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel__prev:hover,
.carousel__next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel__prev {
  left: -24px;
}

.carousel__next {
  right: -24px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  transform: translateY(-20px);
  opacity: 0;
}
.animated-h2 {
  margin-top: 20px;
}
.card-header {
  background: linear-gradient(60deg, #d40778, #610248);
}

.card-header h4 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
.modal-dialog {
  max-width: 750px !important;
  padding: 0 1rem;
}
.seo_title {
  font-size: 1.5rem;
}
.fb-comments,
.fb-comments iframe {
  width: 100% !important;
}

.fb-comments {
  border-radius: 0.5rem;
  overflow: hidden;
}

@media (max-width: 998px) {
  .card.card-box.shadow.zoom-product{
    width: 150px;
    height: 150px;
    margin: auto;
  }
}
@media (max-width: 758px) {
  .hero-page{
    display: flex;
    flex-direction: column;
  }
  .col-md-5{
    margin-top: 1rem !important;
  }
}
</style>
