<template>
    <div class="row position-relative w-100 m-auto">
      <div class="col-12 col-md-12 px-0">
        <div class="row">
          <div class="col px-0 main-slider m-auto">
            <Carousel ref="myCarousel" v-model="currentSlide" v-bind="settings" :breakpoints="breakpoints">
              <Slide v-for="(slide, index) in getBanners" :key="index">
                <div class="slide-con">
                  <a @click.prevent="navigateToGame(slide.url)" target="_blank" rel="noopener noreferrer">
                    <div class="carousel__item" :style="`background-image: url(${slide.img_url})`"></div>
                    <!-- <div class="carousel__item"></div> -->
                  </a>
                </div>
              </Slide>
              <template #addons>
                <Pagination/>
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  import { Carousel, Pagination, Slide } from 'vue3-carousel'
  import 'vue3-carousel/dist/carousel.css'
  
  export default defineComponent({
    name: 'WrapAround',
    components: {
      Carousel,
      Slide,
      Pagination
    },
    props: {
      banners: {
        type: Array,
        default: () => [],
      }
    },
    data () {
      return {
        sliders: [
          { img_url: '' },
          { img_url: '' },
          { img_url: '' },
          { img_url: '' },
        ],
        currentSlide: 0,
        currentSlideBg: 1,
        currentSlideBgTwo: 1,
        breakSlide: 0,
        settings: {
          itemsToShow: 1,
          snapAlign: 'center',
          wrapAround: true,
          autoplay: 3000,
          transition: 800,
          pauseAutoplayOnHover: true,
          speed: 1000,
        },
        breakpoints: {
          1024: { itemsToShow: 1, snapAlign: 'center' },
          991: { itemsToShow: 1, snapAlign: 'center' },
          767: { itemsToShow: 1, snapAlign: 'center' },
          575: { itemsToShow: 1, snapAlign: 'center' },
        },
      }
    },
    computed: {
      getBanners() {
        return this.banners.length >= 4 ? this.banners : this.sliders
      }
    },
    methods: {
      navigateToGame(nameGame) {
        if (nameGame.includes('pubg')) {
          this.$router.push('/game-topup/666986a5156b4e640521c2b0');
        } else if (nameGame.includes('valorant')) {
          this.$router.push('/game-topup/6669830a156b4e640521c214');
        } else if (nameGame.includes('arenabreakout')) {
          this.$router.push('/game-topup/666a8f26a1ff42ef68d26b0d');
        } else {
          return '';
        }
      }
    },
    watch: {
      currentSlide(newValue, oldValue) {
        if (newValue >= oldValue) {
          this.currentSlideBg = newValue + 1;
          this.currentSlideBgTwo = newValue + 2;
        } else {
          this.currentSlideBg = oldValue + 1;
          this.currentSlideBgTwo = oldValue + 2;
        }
      }
    }
  })
  </script>
  
  <style lang="scss" scoped>
  .main-slider {
    .carousel {
      height: auto;
      width: 100% !important;
      margin: 0 !important;
      
      &__slide {
        padding: 0;
        width: 100%;
      }
      
      &__viewport {
        width: 100%;
        padding: 0;
      }
      
      &__track {
        display: flex;
        align-items: center;
      }
    }
  }
  
  .carousel {
    margin: 0 !important;
    
    .carousel__pagination {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
    }
  
    .carousel__pagination-button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      transition: width .3s ease-in-out, background-color .3s ease-in-out;
  
      &.carousel__pagination-button--active {
        width: 30px;
        background-color: #ff5800;
      }
    }
  }
  
  .carousel-fade {
    .carousel__track {
      transition: none !important;
    }
  
    .carousel__slide {
      padding: 0;
    }
  
    &.-l-10 .carousel__slide {
      padding-left: 0;
    }
  
    &.-r-10 .carousel__slide {
      padding-right: 0;
      padding-bottom: 5px;
      padding-top: 5px;
    }
    
    &.-in {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      
      .carousel__slide {
        opacity: 0;
        transition: opacity .5s ease-in-out;
        &.carousel__slide--active {
          opacity: 1;
        }
      }
    }
  }
  
  .carousel__item {
    // background-image: url("https://plus.unsplash.com/premium_photo-1718204436526-277f9f34607c?q=80&w=3009&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") !important;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px; 
    margin: 0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  @media only screen and (max-width: 900px) {
    .carousel__slide {
      padding: 0;
    }
  }
  
  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border: 2px solid white;
    color: #fff;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
  
  .slide-con {
    width: 100%;
    margin: 0;
  }
  
  .position-relative {
    width: 100% !important;
  }
  
  @media only screen and (max-width: 580px) {
    .slide-con {
      width: 100%;
    }
    .carousel__item {
      min-height: 250px;
    }
  }
  </style>
  