<template>

    <div class="order-container">
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-6">
              <label class="form-label">{{ $t('historyOrder.from') }}</label>
              <flat-pickr
                v-model="search.start_date"
                class="form-control datetimepicker"
                placeholder="Please select start date"
                :config="config"
              ></flat-pickr>
            </div>
            <div class="col-6">
              <label class="form-label">{{ $t('historyOrder.to') }}</label>
              <flat-pickr
                v-model="search.end_date"
                class="form-control datetimepicker"
                placeholder="Please select end date"
                :config="config"
              ></flat-pickr>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-label">{{ $t('historyOrder.status') }}</label>
          <select id="choice-remove-button" class="form-control" name="choice-remove-button" v-model="search.status_order">
            <option value="" selected="">{{ $t('historyOrder.total') }}</option>
            <option value="1">{{ $t('historyOrder.pending') }}</option>
            <option value="2">{{ $t('historyOrder.inProcess') }}</option>
            <option value="3">{{ $t('historyOrder.error') }}</option>
            <option value="4">{{ $t('historyOrder.success') }}</option>
            <option value="5">{{ $t('historyOrder.overque') }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="form-label">{{ $t('historyOrder.search') }}</label>
          <div class="input-group">
            <span class="input-group-text"><i class="fas fa-hashtag"></i></span>
            <input class="form-control" placeholder="หมายเลขคำสั่งซื้อ" type="text" v-model="search.order_id">
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-label w-100 visibility-hidden">{{ $t('historyOrder.search') }}</label>
          <button class="btn px-4 w-100 w-md-auto text-lg btn-outline-danger mb-0" @click="searchOrder()"><i class="fas fa-search"></i> {{ $t('historyOrder.search') }}</button>
        </div>
        <div class="col-md-4">
          <label class="form-label w-100 visibility-hidden">Refresh</label>
          <div class="dropdown d-flex justify-content-end">
            <button class="btn btn-outline-danger dropdown-toggle mb-0 px-4 w-100 text-lg text-left" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <span v-if="intervalId"> {{ $t('historyOrder.refresh1') }} {{ getRefreshTime / 1000 }} {{ $t('historyOrder.second') }}</span>
              <span v-if="!intervalId">{{ $t('historyOrder.refresh') }}</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li><a class="dropdown-item" href="javascript:;" @click="setRefreshTime(30000)">{{ $t('historyOrder.refresh30') }}</a></li>
              <li><a class="dropdown-item" href="javascript:;" @click="setRefreshTime(15000)">{{ $t('historyOrder.refresh15') }}</a></li>
              <li><a class="dropdown-item" href="javascript:;" @click="setRefreshTime(10000)">{{ $t('historyOrder.refresh10') }}</a></li>
              <li><a class="dropdown-item" href="javascript:;" @click="setRefreshTime(5000)">{{ $t('historyOrder.refresh5') }}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row" v-if="orders && orders.length">
        <div class="col-12" v-for="(order, index) in filterOrder" :key="index">
          <div id="basic-info" class="card mt-4 blur-dark-fade">
            <div class="p-4">
              <div class="d-flex justify-content-between">
                <span>{{ getGameName(order) }}</span>
                <a href="https://lin.ee/dvfKmBo" target="_blank">
                  <button v-if="order.status_order === 3" class="btn w-md-auto text-sm btn-danger mb-2 p-2">{{ $t('historyOrder.contactAdmin') }}</button>
                </a>
              </div>
              <div class="d-flex justify-content-between align-item-center">
                <h5 class="text-white m-0"><i class="fas fa-receipt"></i> {{ $t('historyOrder.noOrder') }}</h5>
                <div class="text-right mb-1">
                  <h5 class="text-warning m-0" v-if="order.status_order === 1">{{ $t('historyOrder.pending') }}</h5>
                  <h5 class="text-info m-0" v-if="order.status_order === 2">{{ $t('historyOrder.inProcess') }}</h5>
                  <h5 class="text-primary m-0" v-if="order.status_order === 3">{{ $t('historyOrder.error') }}</h5>
                  <h5 class="text-success m-0" v-if="order.status_order === 4">{{ $t('historyOrder.success') }}</h5>
                  <h5 class="text-danger m-0" v-if="order.status_order === 5">{{ $t('historyOrder.overque') }}</h5>
                </div>
              </div>
              <div class="d-flex justify-content-between flex-md-row flex-column">
                <span class="m-0 text-white d-flex gap-3 justify-content-between aling-items-center">{{ getOrderCode(order) }} <button @click.prevent="copyToClipboard(getOrderCode(order))" class="btn btn-xs btn-outline-white mb-2 p-1"><i class="fas fa-copy"></i> {{ $t('copy') }}</button></span>
                <small><i class="fas fa-clock"></i> {{ moment(order.created_date_iso).format('DD/MM/YYYY HH:mm') }}</small>
              </div>
              <ul class="mb-0 p-0 list-unstyled" v-if="order.order_product_id_arr && order.order_product_id_arr.length">
                <li v-for="(item, index2) in order.order_product_id_arr" :key="index2" class="d-flex gap-3 align-items-center mb-1">
                   
                 <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2 gap-3">
                      <img :src="item.product_id.img_url" class="" width="50" alt="">{{ item.product_id?.name }} x {{ item.product_total }}
                      
                    </div>

                    <img :src="item.img_url" v-if="item.img_url" class="img img-fuld rounded w-100">
                 </div>
                </li>
              </ul>
              <hr>
              <div class="my-1">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-white fs-6">{{ $t('historyOrder.information') }}</div>
                  <button class="btn btn-danger m-0" style="font-size: 14px;" @click="viewCard(order)" v-if="order.remark">{{ getGameName(order) }} Code</button>
                </div>
                <div v-if="order.detail && isJson(order.detail)" class="row position-relative z-index-1 mt-1">
                  <div v-for="(f, index) in fetchRawData(order.detail)" :key="index" class="col-6 mb-1">
                    <!-- แยกการตรวจสอบ image url ออกเป็น div -->
                    <div v-if="index === 'img_url'">
                      <div class="d-flex flex-wrap">
                        <div v-for="(img, imgIndex) in f" :key="imgIndex" class="image-container">
                          <img :src="img" class="uploaded-image" />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="index != 'game_server'">
                        <div class="row">
                          <div class="col-12">
                            <label class="m-0 text-danger" for="">{{index}}</label>
                            <p class="text-white" v-if="index === 'Password'">******</p>
                            <p class="text-white" v-else>{{ f }}</p>
                          </div>
                        </div>
                      </div>
                      <div v-if="index == 'game_server'">
                        <div v-for="(g, g_dex) in f" :key="g_dex">
                          <label class="m-0 text-danger" for="">{{g_dex}}</label>
                          <p class="text-white">{{ g }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <h6 class="text-white text-md m-0"><i class="fas fa-coins"></i> {{ this.$filters.toCurrency(order.sale_total) }}</h6>
              <div class="alert alert-danger d-flex gap-2 mt-2" role="alert" v-if="order.issue_remark">
                <span class="alert-icon text-white"><i class="fas fa-exclamation-circle"></i></span>
                <span class="alert-text text-white w-100 w-mobile">{{ order.issue_remark }}</span>
              </div>
              <div class="alert alert-success d-flex gap-2 mt-2" role="alert" v-if="(order.detail.includes('price_id_pass') || order.detail.includes('quantity_id_pass') || order.detail.includes('description_id_pass')) && (order.status_order != 4)">
                <span class="alert-icon text-black"><i class="fas fa-check"></i></span>
                <span class="alert-text text-black" v-html="$t('historyOrder.alertMessage')"></span>
              </div>
            </div>
          </div>
        </div>
        
        <!-- <div class="col-12">
          <div id="basic-info" class="card mt-4 blur-dark-fade">
            <div class="p-4">
              <div class="d-flex justify-content-between">
                <div class="">
                  <h5 class="text-white">Order #2 <label class="badge bg-success text-dark"><i class="fas fa-coins"></i> 3,000</label></h5>
                </div>
                <div class=" text-right">
                  <h5 class="text-success">สำเร็จ</h5>
                </div>
              </div>
              <ul class="mb-0">
                <li>Genshin 3,000 Gems x 1</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div class="col-12">
          <div id="basic-info" class="card mt-4 blur-dark-fade">
            <div class="p-4">
              <div class="d-flex justify-content-between">
                <div class="">
                  <h5 class="text-white">Order #1 <label class="badge border text-dark"><i class="fas fa-coins"></i> 5,000</label></h5>
                </div>
                <div class=" text-right">
                  <h5 class="text-red">ยกเลิก</h5>
                </div>
              </div>
              <ul class="mb-0">
                <li>FIFA 1,000 cash x 2</li>
                <li>FIFA 3,000 cash x 1</li>
              </ul>
            </div>
          </div>
        </div> -->

      </div>
      <div class="row" v-else>
        <div class="col-12">
          <div id="basic-info" class="card mt-4 blur-dark-fade py-5">
            <h4 class="text-center opacity-5"><i class="fas fa-shopping-basket fa-4x"></i></h4>
            <h4 class="mb-0 text-center opacity-5">{{ $t('historyOrder.notFoundOrder') }}</h4>
          </div>
        </div>
      </div>
      <div v-if="showModal" class="modal-overlay" @click="showModal = false">
        <div class="modal-content" @click.stop>
          <div class="card blur-dark-fade">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <div class="d-flex justify-content-between align-items-center flex-wrap">
                    <div class="d-flex justify-content-center align-items-center gap-1" style="max-width: 350px;">
                      <p class="fs-5 fw-bold text-light p-0 m-0" style="width: 70px;">Pin:</p>
                      <p class="fs-6 text-light p-0 m-0 fw-bold letter-spacing-1">{{ this.modalRef1 }}</p>
                    </div>
                    <button @click.prevent="copyToClipboard(this.modalRef1)" class="btn btn-xs btn-outline-success mb-0 p-2 d-none d-sm-block m-0">
                      <i class="fas fa-copy"></i> {{ $t('copy') }}
                    </button>
                    <button @click.prevent="copyToClipboard(this.modalRef1)" class="btn btn-xs btn-outline-success mb-0 p-2 d-block d-sm-none m-0">
                      <i class="fas fa-copy"></i>
                    </button>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <div class="d-flex justify-content-between align-items-center flex-wrap">
                    <div class="d-flex justify-content-center align-items-center gap-1" style="max-width: 350px;">
                      <p class="fs-5 fw-bold text-light p-0 m-0" style="width: 70px;">Code:</p>
                      <p class="fs-6 text-light p-0 m-0 fw-bold letter-spacing-1">{{ this.modalRef2 }}</p>
                    </div>
                    <button @click.prevent="copyToClipboard(this.modalRef2)" class="btn btn-xs btn-outline-success mb-0 p-2 d-none d-sm-block m-0">
                      <i class="fas fa-copy"></i> {{ $t('copy') }}
                    </button>
                    <button @click.prevent="copyToClipboard(this.modalRef2)" class="btn btn-xs btn-outline-success mb-0 p-2 d-block d-sm-none m-0">
                      <i class="fas fa-copy"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button @click="showModal = false" class="close-btn">{{ $t('historyOrder.closeWindow') }}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import SoftBadge from "@/components/SoftBadge.vue";
  import flatPickr from "vue-flatpickr-component";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  export default {
    name: "NavPillOrder",
    components: {
      // SoftBadge,
      flatPickr,
    },
    props: {
      orderData: {
        type: Function,
        default: () => {}
      }
    },
    data() {
      return {
        search: {
          start_date: '',
          end_date: '',
          order_id: '',
          status_order: '',
        },
        moment: moment,
        refreshTime: 30000,
        intervalId: null,
        showModal: false,
        modalRef1: null,
        modalRef2: null,
      }
    },
    computed: {
      ...mapGetters(['orders']),
      getRefreshTime() {
        return this.refreshTime;
      },
      filterOrder() {
        const filter_order = this.orders.filter(x => {
          const date = new Date(x.created_date_iso);
          const year = date.getFullYear() % 100;
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const fake_id = `GO-${year}${month}-${this.padAndReplace(8, x.index)}`.toLowerCase()
          return fake_id.includes(this.search.order_id.toLowerCase())
        })
        // console.log('filter_order: ', filter_order);
        return filter_order && filter_order.length ? filter_order : [];
      },
    },
    beforeMount() {
      
    },
    async created() {
      await this.setRefreshTime(5000);
    },
    methods: {
      ...mapActions(['fetchOrders']),
      padAndReplace(range, number) {
          const paddedNumber = number.toString().padStart(range, '0');
          return paddedNumber;
      },
      getOrderCode(order) {
        const date = new Date(order.created_date_iso);
        const year = date.getFullYear() % 100;
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `GO-${year}${month}-${this.padAndReplace(8, order.index)}`
      },
      fetchRawData(rawData) {
        const json_raw = rawData.trim()
        if (json_raw) {
          return JSON.parse(json_raw)
        }
        return null
      },
      isJson(str)
      {
        try
        {
          let j = JSON.parse(str);
          return true;
        }
        catch(e){
          return false;
        }
      },
      copyToClipboard(clipboardText) {
      try {
        const el = document.createElement('textarea');
        el.value = clipboardText;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        const Toast = this.$swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'success',
          title: this.$t('historyOrder.copySuccess')
        })
      } catch (error) {
        
      }
      },
      getGameName(order) {
        if (order.order_product_id_arr && order.order_product_id_arr.length) {
          return order.order_product_id_arr[0]?.product_id?.game_id?.name;
        }
        return 'Unknows';
      },
      startInterval() {
        this.intervalId = setInterval(() => {
          this.searchOrder();
        }, parseInt(this.refreshTime));
      },
      stopInterval() {
        clearInterval(this.intervalId);
      },
      changeInterval() {
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.startInterval();
        }
      },
      setRefreshTime(time) {
        this.refreshTime = time;
        if (this.intervalId) {
          this.stopInterval();
        }
        this.startInterval();
      },
      setFilter(value) {
        this.$emit('filter-order', value);
      },
      searchOrder() {
        this.$store.dispatch('fetchOrderByFilter', {
          start_date: this.search.start_date ? this.convertDate(this.search.start_date) : '', 
          end_date: this.search.end_date ? this.convertDate(this.search.end_date) : '',
          status_order: this.search.status_order
        });
      },
      convertDate(inputFormat) {
        const date = new Date(inputFormat);
        let month = (date.getMonth() + 1).toString(); // Months are zero based
        let day = date.getDate().toString();
        const year = date.getFullYear().toString();
        
        // Ensure month and day are two digits
        month = (month.length < 2) ? '0' + month : month;
        day = (day.length < 2) ? '0' + day : day;

        return month + '/' + day + '/' + year;
      },
      viewCard(data){
        const remarkObj = JSON.parse(data.remark);
        this.modalRef1 = remarkObj.ref1;
        this.modalRef2= remarkObj.ref2;
        this.showModal = true
      }
    },
    beforeRouteLeave(to, from, next) {
      this.stopInterval();
      next();
    },
    unmounted() {
      this.stopInterval(); // Make sure to clear the interval on component destruction
    }

  };
  </script>
  
  <style scoped lang="scss">
  .nav-container {
      display: flex;
      align-items: center;
      justify-content: end;
      position: relative;
  }
  .tabs {
      display: flex;
      position: relative;
      width: 100%;
      background: transparent;
      backdrop-filter: blur(15px);
      padding: 0.75rem;
      justify-content: space-around;
      box-shadow: inset 0 0px 1px 1px rgba(84, 84, 84, 0.9), 0 20px 27px 0 rgba(82, 82, 82, 0.05) !important;
      border-radius: .5rem; // just a high number to create pill effect
      * {
          z-index: 2;
      }
  }
  
  input[type="radio"] {
      display: none;
  }
  
  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 36px;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 99px; // just a high number to create pill effect
    cursor: pointer;
    transition: color 0.15s ease-in;
    margin: 0;
  }

  .glider {
    position: absolute;
    display: flex;
    height: 36px;
    width: 25%;
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(10px);
    z-index: 1;
    border-radius: 0.65rem;
    transition: .3s ease-in-out;
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 0.5rem;
      padding: 1px 1px 1px 1px;
      background: linear-gradient(20deg, #ff5800, #000 56%);
      -webkit-mask: linear-gradient(#000000 0 0) content-box, linear-gradient(#000000 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
  
  .notification {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      margin-left: 0.75rem;
      border-radius: 50%;
      background-color: #e6eef9;
      transition: 0.15s ease-in;
    color: #0a0a0a;
  }
  
  input[type="radio"] {
      &:checked {
          & + label {
              color: #ff5800;
              & > .notification {
                  background-color: #ff5800;
                  color: #fff;
              }
          }
      }
  }
  
  input[id="radio-1"] {
      &:checked {
          & ~ .glider {
              transform: translateX(-145%);
          }
      }
  }
  
  input[id="radio-2"] {
      &:checked {
          & ~ .glider {
              transform: translateX(-50%);
          }
      }
  }
  
  input[id="radio-3"] {
      &:checked {
          & ~ .glider {
              transform: translateX(50%);
          }
      }
  }
  
  input[id="radio-4"] {
      &:checked {
          & ~ .glider {
              transform: translateX(145%);
          }
      }
  }
  .image-container{
    img {
      width: 120px;
      margin-right: 15px;
      margin-bottom: 15px
    }
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .modal-content {
    background-color: #1d1d1d;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    margin-top: 7%;
  }
  
  .close-btn {
    align-self: center;
    padding: 8px 15px;
    background-color: #e00b60;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
  }
  
  .close-btn:hover {
    background-color: #ff1a75;
  }
  .btn-outline-success{
    margin-right: 1rem;
  }
  .card-body{
    padding-bottom: 0;
  }
  .dropdown-toggle::after{
    display: none;
  }
  .dropdown .dropdown-menu.show:before{
    display: none;
  }
  @media (max-width: 700px) {
    .nav-container {
      justify-content: center;
    }
      .tabs {
      flex-flow: column;
      width: 97%;
      justify-content: center;
      align-items: center;
      }
    .glider {
      width: 97%;
    }
    input[id="radio-1"] {
      &:checked {
        & ~ .glider {
          transform: translateY(-100%);
        }
      }
    }
  
    input[id="radio-2"] {
      &:checked {
        & ~ .glider {
          transform: translateY(0);
        }
      }
    }
  
    input[id="radio-3"] {
      &:checked {
        & ~ .glider {
          transform: translateY(100%);
        }
      }
    }
    
    input[id="radio-4"] {
      &:checked {
        & ~ .glider {
          transform: translateY(100%);
        }
      }
    }

    .w-mobile{
      width: 90% !important;
    }
    .fs-2{
      font-size: 26px !important;
    }
    .letter-spacing-1{
      font-size: 18px !important;
      letter-spacing: normal !important;
    }
    .btn-outline-success{
      margin-right: 0;
    }
    .card-body{
      padding-bottom: 0;
    }
    .btn-danger{
      padding: 15px;
      font-size: 16px !important;
    }
  }
  @media (max-width: 500px) {
    .fs-2{
      font-size: 18px !important;
    }
    .fs-5 {
      width: 60px !important;
    }
    .letter-spacing-1{
      font-size: 14px !important;
      letter-spacing: 1px !important;
    }
    .btn-outline-success{
      font-size: 12px !important;
      padding: 0.3rem 0.5rem !important;
      text-align:center;
      margin-top: 0.8rem;
      margin-right: 0;
    }
    .close-btn{
      font-size: 12px !important;
      padding: 5px 14px !important;
    }
    .card .card-body{
      padding: 20px 10px 0px;
    }
    .modal-content {
      padding: 20px 10px;
    }
    .btn-danger{
      padding: 10px;
      font-size: 12px !important;
    }
  }

  </style>