<template>
  <div class="navbar navbar-expand top-0 z-index-3 shadow py-2 start-0 end-0 fixed-top d-flex flex-column">
    <div class="container-fluid w-100">
      <div class="d-flex nav-top-con w-100">
        <nav class="container d-flex justify-content-between align-items-center w-80">
          <marquee behavior="scroll" direction="left" style="background-color: white;" class="text-dark">
            ข่าวด่วน: ส่วนลดพิเศษสำหรับลูกค้าใหม่! สมัครวันนี้รับส่วนลด 50% ทันที!
          </marquee>
        </nav>
        <nav class="w-20 d-md-flex d-none">
         <ul class="navbar-nav align-items-center">
            <li v-if="getMemberProfile" class="nav-item d-flex justify-content-center align-items-center special-con shiny-effect">
              <button
                  v-if="getMemberProfile"
                  class="navbar-toggler mb-0 me-1 text-lg text-white justify-content-between align-items-center d-inline-block w-100"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasDashboard"
                  aria-controls="offcanvasDashboard"
                  aria-label="Toggle navigation"
                >
                <div class="d-flex gap-2 justify-content-center align-items-center">
                  <div>
                    <i v-if="getMemberProfile.username" class="fas fa-user-circle fs-2"></i>
                  </div>
                  <div>
                    <span class="profile">
                      <span class="name" style="font-size: 18px">{{ menu_auth[5] ? `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` : `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` }}</span> 
                    </span> 
                    <!-- <span class="wallet">{{ this.$filters.toCurrency(getMemberProfile.credit_total) }} 
                      <i v-if="getMemberProfile.credit_total" class="fas fa-coins text-lg"></i>
                    </span> -->
                  </div>
                </div>
              </button>
              <!-- <span  class="mt-3"><button class="bg-transparent border-0" @click="refreshCreditTotal"><i class="fas fa-redo text-white fs-6"></i></button></span> -->
            </li>
            <li class="nav-item" v-if="!getMemberProfile">
              <router-link
                to="/signup"
                class="mb-0 me-1 text-lg text-white px-2 text-nowrap font-weight-bold border-r"
                >{{ $t('navbar.signUp') }}</router-link>
            </li>
            <li class="nav-item" v-if="!getMemberProfile">
              <router-link
                to="/signin"
                class="mb-0 me-1 text-lg text-white px-2 text-nowrap font-weight-bold login-menu"
                >{{ $t('navbar.signIn') }}</router-link>
            </li>
          </ul>
        </nav>
        <nav class="container d-md-none w-100">
          <div class="row w-100 d-flex justify-content-center align-items-center mx-auto">
            <div class="col-2 p-0">
              <router-link
                class="navbar-brand font-weight-bolder ms-xl-0 mr-2"
                :class="darkMode ? 'text-black' : 'text-white'"
                to="/"
                >
                <img src="@/assets/img/logo.png" alt="" height="60" class="d-inline-block w-auto">
              </router-link>
            </div>
            <div class="col-4 p-0 text-center">
              <router-link to="/coin-topup" class="cursor-pointer">
                <button class="border-0 coins-box"><i class="fa fa-plus me-1"></i>{{ $t('navbar.topup')}}</button>
              </router-link>
            </div>
            <div class="col-6 p-0">
              <ul v-if="getMemberProfile" class="ml-auto d-flex d-xl-none align-items-center list-unstyled m-0">
                <li v-if="getMemberProfile" class="nav-item d-flex justify-content-center align-items-center special-con shiny-effect">
                  <button
                      v-if="getMemberProfile"
                      class="navbar-toggler mb-0 me-1 text-lg text-white justify-content-between align-items-center d-inline-block w-100 p-0"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasDashboard"
                      aria-controls="offcanvasDashboard"
                      aria-label="Toggle navigation"
                    >
                    <div class="d-flex gap-2 justify-content-center align-items-center p-0">
                      <div>
                        <i v-if="getMemberProfile.username" class="fas fa-user-circle fs-2"></i>
                      </div>
                      <div>
                        <span class="profile">
                          <span class="name" style="font-size: 18px">{{ menu_auth[5] ? `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` : `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` }}</span> 
                        </span> 
                        <!-- <span class="wallet">{{ this.$filters.toCurrency(getMemberProfile.credit_total) }} 
                          <i v-if="getMemberProfile.credit_total" class="fas fa-coins text-lg"></i>
                        </span> -->
                      </div>
                    </div>
                  </button>
                  <!-- <span  class="mt-3"><button class="bg-transparent border-0" @click="refreshCreditTotal"><i class="fas fa-redo text-white fs-6"></i></button></span> -->
                </li>
                <li>
                  <button
                    class="shadow-none navbar-toggler p-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navigation"
                    aria-controls="navigation"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="mt-2 navbar-toggler-icon">
                      <span class="navbar-toggler-bar bar1"></span>
                      <span class="navbar-toggler-bar bar2"></span>
                      <span class="navbar-toggler-bar bar3"></span>
                    </span>
                  </button>
                </li>
              </ul>
              <ul v-if="!getMemberProfile" class="ml-auto d-flex d-xl-none align-items-center list-unstyled m-0">
                <li class="nav-item" v-if="!getMemberProfile">
                  <router-link
                    to="/signup"
                    class="mb-0 me-1 text-lg text-white px-2 text-nowrap font-weight-bold border-r"
                    >{{ $t('navbar.signUp') }}</router-link>
                </li>
                <li class="nav-item" v-if="!getMemberProfile">
                  <router-link
                    to="/signin"
                    class="mb-0 me-1 text-lg text-white px-2 text-nowrap font-weight-bold login-menu"
                    >{{ $t('navbar.signIn') }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="navigation"
            class="pt-3 pb-2 collapse navbar-collapse w-100 py-xl-0"
          >
          <ul class="nav flex-column border-radius-lg p-3">
            <li class="nav-item text-start" v-for="(menu, index) in translatedMenu" :key="index">
              <template v-if="menu.rules == '2'">
                <a
                  :href="menu.path"
                  class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                {{ $t(menu.title) }} 
                </a>
              </template>
              <template v-else>
                <router-link
                  :to="menu.path"
                  :class="['cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center']"
                >
                  {{ $t(menu.title) }} 
                </router-link>
              </template>
            </li>
            <li class="nav-item text-start" v-if="getMemberProfile">
              <router-link
                to="/member/order"
                class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center w-100 profile"
                ><span class="name">{{ $t('navbar.dashboard') }} {{ menu_auth[5] ? `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` : `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` }}</span></router-link>
            </li>
          </ul>
          </div>
        </nav>
      </div>
    </div>
    <div  v-if="getMemberProfile" class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDashboard" aria-labelledby="offcanvasDashboardLabel" aria-modal="true" role="dialog">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasDashboardLabel">Offcanvas</h5>
        <!-- <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>       -->
      </div>
      <div class="offcanvas-body d-flex flex-column p-3">
        <div class="d-flex gap-2 justify-content-start align-items-center position-relative">
          <i v-if="getMemberProfile.username" class="fas fa-user-circle fs-1 text-white"></i>
          <div>
            <span class="profile">
              <span class="name text-white" style="font-size: 18px">{{ menu_auth[5] ? `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` : `${getMemberProfile.full_name.split('@')[0] || getMemberProfile.username.split('@')[0] || ''}` }}</span> 
            </span> 
            <br>
            <button @click="refreshCreditTotal" class="btn btn-success p-1"><i class="fas fa-redo text-white"></i> รีเฟรช เครดิต</button>
          </div>
          <button type="button" class="btn-close btn-close-white position-absolute top-0 end-0" data-bs-dismiss="offcanvas" aria-label="Close"></button>       
        </div>
        <hr style="height: 1px; background-color:white;">
        <div class="row d-flex align-items-center">
          <div class="col-7">
            <p class="text-white mb-0">เครดิตคงเหลือ:
              <span class="wallet text-white  ">{{ this.$filters.toCurrency(getMemberProfile.credit_total) }} 
                <i v-if="getMemberProfile.credit_total" class="fas fa-coins text-lg"></i>
              </span>
            </p>
          </div>
          <router-link to="/coin-topup" class="cursor-pointer col-5">
            <button class="border-0 coin-box btn btn-info p-2 w-100 mb-0"><i class="fa fa-plus me-1"></i>{{ $t('navbar.topup')}}</button>
          </router-link>
        </div>
        <div class="row d-flex align-items-center mt-3">
          <div class="col-7">
            <p class="text-white mb-0">แลกรหัสของขวัญ</p>
          </div>
          <router-link to="/member/redeem" class="cursor-pointer col-5">
            <button class="border-0 coin-box btn btn-info p-2 w-100 mb-0"><i class="fa fa-gift me-1"></i>ไปหน้ากรอกรหัส</button>
          </router-link>
        </div>
        <hr style="height: 1px; background-color:white;">
        <div>
          <ul class="nav flex-column border-radius-lg p-3">
            <li class="nav-item text-start">
              <router-link class="nav-link text-body" data-scroll to="/member/order">
                <div class="icon me-2">
                  <svg
                    class="text-dark mb-1"
                    width="16px"
                    height="16px"
                    viewBox="0 0 42 42"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>box-3d-50</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        transform="translate(-2319.000000, -291.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(603.000000, 0.000000)">
                            <path
                              class="color-background"
                              d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                            />
                            <path
                              class="color-background"
                              d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                              opacity="0.7"
                            />
                            <path
                              class="color-background"
                              d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                              opacity="0.7"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span class="text-sm">{{ $t('sideNav.historyOrder') }}</span>
              </router-link>
            </li>
            <li class="nav-item text-start">
              <router-link class="nav-link text-body" data-scroll to="/member/order/topup">
                <div class="icon me-2">
                  <svg
                    class="text-dark mb-1"
                    width="16px"
                    height="16px"
                    viewBox="0 0 40 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>document</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        transform="translate(-1870.000000, -591.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(154.000000, 300.000000)">
                            <path
                              class="color-background"
                              d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                              opacity="0.603585379"
                            />
                            <path
                              class="color-background"
                              d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span class="text-sm">{{ $t('sideNav.historyTopup') }}</span>
              </router-link>
            </li>
            <li class="nav-item text-start">
              <router-link class="nav-link text-body" data-scroll to="/member/profile">
                <div class="icon me-2">
                  <svg
                    class="text-dark mb-1"
                    width="16px"
                    height="16px"
                    viewBox="0 0 40 40"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>spaceship</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        transform="translate(-1720.000000, -592.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(4.000000, 301.000000)">
                            <path
                              class="color-background"
                              d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"
                            />
                            <path
                              class="color-background"
                              d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"
                            />
                            <path
                              class="color-background"
                              d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z"
                              opacity="0.598539807"
                            />
                            <path
                              class="color-background"
                              d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z"
                              opacity="0.598539807"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span class="text-sm">{{ $t('sideNav.dashboard') }}</span>
              </router-link>
            </li>
            <li class="nav-item text-start">
              <a class="nav-link text-body" data-scroll href="#" @click.stop="logOut">
                <div class="icon me-2">
                  <svg
                    class="text-dark mb-1"
                    width="16px"
                    height="16px"
                    viewBox="0 0 45 40"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>shop</title>
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        transform="translate(-1716.000000, -439.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(0.000000, 148.000000)">
                            <path
                              class="color-background"
                              d="M46.7199583,10.7414583 L40.8449583,0.949791667 C40.4909749,0.360605034 39.8540131,0 39.1666667,0 L7.83333333,0 C7.1459869,0 6.50902508,0.360605034 6.15504167,0.949791667 L0.280041667,10.7414583 C0.0969176761,11.0460037 -1.23209662e-05,11.3946378 -1.23209662e-05,11.75 C-0.00758042603,16.0663731 3.48367543,19.5725301 7.80004167,19.5833333 L7.81570833,19.5833333 C9.75003686,19.5882688 11.6168794,18.8726691 13.0522917,17.5760417 C16.0171492,20.2556967 20.5292675,20.2556967 23.494125,17.5760417 C26.4604562,20.2616016 30.9794188,20.2616016 33.94575,17.5760417 C36.2421905,19.6477597 39.5441143,20.1708521 42.3684437,18.9103691 C45.1927731,17.649886 47.0084685,14.8428276 47.0000295,11.75 C47.0000295,11.3946378 46.9030823,11.0460037 46.7199583,10.7414583 Z"
                              opacity="0.598981585"
                            />
                            <path
                              class="color-foreground"
                              d="M39.198,22.4912623 C37.3776246,22.4928106 35.5817531,22.0149171 33.951625,21.0951667 L33.92225,21.1107282 C31.1430221,22.6838032 27.9255001,22.9318916 24.9844167,21.7998837 C24.4750389,21.605469 23.9777983,21.3722567 23.4960833,21.1018359 L23.4745417,21.1129513 C20.6961809,22.6871153 17.4786145,22.9344611 14.5386667,21.7998837 C14.029926,21.6054643 13.533337,21.3722507 13.0522917,21.1018359 C11.4250962,22.0190609 9.63246555,22.4947009 7.81570833,22.4912623 C7.16510551,22.4842162 6.51607673,22.4173045 5.875,22.2911849 L5.875,44.7220845 C5.875,45.9498589 6.7517757,46.9451667 7.83333333,46.9451667 L19.5833333,46.9451667 L19.5833333,33.6066734 L27.4166667,33.6066734 L27.4166667,46.9451667 L39.1666667,46.9451667 C40.2482243,46.9451667 41.125,45.9498589 41.125,44.7220845 L41.125,22.2822926 C40.4887822,22.4116582 39.8442868,22.4815492 39.198,22.4912623 Z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <span class="text-sm">{{ $t('sideNav.logout') }}</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- <div>
          <span class="wallet text-white  ">{{ this.$filters.toCurrency(getMemberProfile.credit_total) }} 
            <i v-if="getMemberProfile.credit_total" class="fas fa-coins text-lg"></i>
          </span>
        </div> -->
      </div>
    </div>

    <nav
      class="w-100 d-md-flex d-none"
      :class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'"
    >
      <div class="container d-flex justify-content-between align-items-center w-100">
        <!-- Left menu -->
        <ul class="navbar-nav d-flex justify-content-start align-items-center w-40">
          <li class="mx-2 nav-item" v-for="(menu, index) in leftMenu" :key="index">
            <template v-if="menu.rules == '2'">
              <a
                :href="menu.path"
                class="cursor-pointer nav-link d-flex justify-content-center align-items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ $t(menu.title) }} 
              </a>
            </template>
            <template v-else>
              <router-link
                :to="menu.path"
                class="cursor-pointer nav-link d-flex justify-content-center align-items-center"
              >
                {{ $t(menu.title) }} 
              </router-link>
            </template>
          </li>
        </ul>
  
        <!-- Logo -->
        <router-link
          class="navbar-brand mx-auto w-20 d-flex justify-content-center align-items-center"
          :class="darkMode ? 'text-black' : 'text-white'"
          to="/"
        >
          <img src="@/assets/img/logo.png" alt="Logo" class="logo">
        </router-link>
  
        <!-- Right menu -->
        <ul class="navbar-nav d-flex justify-content-end align-items-center w-40">
          <li class="nav-item mx-2" v-for="(menu, index) in rightMenu" :key="index">
            <template v-if="menu.rules == '2'">
              <a
                :href="menu.path"
                class="nav-link d-flex justify-content-center align-items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ $t(menu.title) }}
              </a>
            </template>
            <template v-else>
              <router-link :to="menu.path" class="nav-link d-flex justify-content-center align-items-center">
                {{ $t(menu.title) }}
              </router-link>
            </template>
          </li>
        </ul>
      </div>
    </nav>

  </div>
</template>

<script>
/*eslint-disable*/
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";
import Office from "../../components/Icon/Office.vue";
import Shop from "../../components/Icon/Shop.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Document from "../../components/Icon/Document.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import GettingStarted from "../../components/Icon/GettingStarted.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import Settings from "../../components/Icon/Settings.vue";
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "Navbar",
  components: {
    Office,
    Shop,
    CustomerSupport,
    Document,
    Spaceship,
    GettingStarted,
    Box3d,
    Settings
  },
  props: {
    btnBackground: {
      type: String,
      default: "",
    },
    isBlur: {
      type: String,
      default: "",
    },
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downArrWhite,
      downArrBlack,
      currentLang:'th', // ดึงค่าภาษาจาก localStorage หรือใช้ค่าเริ่มต้น
    };
  },
  created() {
    const storedLang = localStorage.getItem('language');
    if (storedLang) {
      this.currentLang = storedLang;
      this.$i18n.locale = storedLang;
    } else {
      this.$i18n.locale = this.currentLang; 
    }

    this.getProfile(); 
  },
  beforeMount() {
  },  
  // mounted() {
  //   this.$i18n.locale = this.currentLang; // ตั้งค่าภาษาที่เลือกเมื่อคอมโพเนนต์โหลด
  // },
  computed: {
    ...mapGetters(['getSetting', 'getMemberProfile']),
    leftMenu() {
      return this.menu_not_auth.slice(0, 3); // 3 เมนูแรกสำหรับเมนูซ้าย
    },
    rightMenu() {
      return this.menu_not_auth.slice(3, 6); // 3 เมนูสุดท้ายสำหรับเมนูขวา
    },
    menu_auth() {
      if (this.getSetting.menu_tem) {
        return this.getSetting?.menu_tem.filter(x => x.auth)
      }
      return []
    },
    menu_not_auth() {
      if (this.getSetting.menu_tem) {
        // console.log('this.getSetting.menu_tem: ', this.getSetting.menu_tem);
        return this.getSetting?.menu_tem.filter(x => !x.auth)
      }
      return []
    },
    darkModes() {
      return {
        "text-dark": this.darkMode
      };
    },
    translatedMenu() {
      return this.menu_not_auth.map(menu => ({
        ...menu,
        title: this.getTranslationKey(menu.title) // แปลง title เป็น key
      }));
    }
  },
  methods: {
    ...mapActions(['getProfile']),
    async refreshCreditTotal() {
    if (this.isRefreshing) {
      this.$swal({
        icon: "warning",
        title: "รอสักครู่",
        text: "ไม่สามารถอัพเดตหลายครั้งได้ รอ 5 วินาที"
      });
      return;
    }

    this.isRefreshing = true;

    try {
      await this.getProfile();
    } catch (error) {
      console.error("เกิดข้อผิดพลาดขณะอัปเดตยอดเครดิต:", error);
      this.$swal({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: "ไม่สามารถอัปเดตยอดเครดิตได้"
      });
    } finally {
      setTimeout(() => {
        this.isRefreshing = false; // ปล่อยให้สามารถกดได้หลังจาก 10 วินาที
      }, 10000); // 10000 มิลลิวินาที = 10 วินาที
    }
  },
  changeLanguage(lang) {
      this.currentLang = lang;
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang); 
    },
    getTranslationKey(title) {
      // แปลง title เป็น key ตามไฟล์แปล
      const keyMapping = {
        'หน้าหลัก': 'home',
        'เติมเกม ID/PASS': 'topup_id_pass',
        'เติม เครดิต': 'coin_topup',
        'ข่าวสารและโปรโมชั่น': 'news_promotions',
        'ซื้อขายไอเท็ม': 'buy_sell_items',
        'ติดต่อเรา': 'contact_us',
        'โปรไฟล์': 'profile',
        'เข้าสู่ระบบ': 'sign_in'
      };
      return keyMapping[title] || title; 
    }
  },
  watch: {
    '$route': async function(to, from) {
      if (!this.getMemberProfile) {
        await this.$store.dispatch('logOut');
        this.getProfile();
      }
    }
  }

};
</script>

<style scoped lang="scss">
.navbar {
  background-color: #000 !important;
}

.navbar-nav {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.nav-item {
  text-align: center;
  width: 100%;
  position: relative; 
}

.nav-link {
  color: #fff !important;
  padding: 8px 15px;
  transition: color 0.3s ease; 
}

.nav-link:hover {
  color: #ff5800 !important; 
}

.nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #ff5800; 
  transition: width 0.3s ease;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navbar-brand {
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 0 auto;
}
.navbar-brand img{
  transition: transform 0.3s ease, box-shadow 0.3s ease;

}
.navbar-brand img:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.nav-link:hover::after {
  width: 100%;
}


.nav-link:hover {
  transform: scale(1.05); 
  transition: transform 0.3s ease-in-out;
}

.logo {
  height: 60px;
  width: auto;
}

.blur {
  box-shadow: inset 0px 0px 2px #ff5800;
  backdrop-filter: blur(10px);
  background-color: rgba(45, 45, 45, 0.35) !important;
}

.offcanvas {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 400px; 
  overflow: hidden;
  }

.offcanvas.show {
  transform: translateX(0); 
}

.offcanvas:not(.show) {
  transform: translateX(100%);
}
.navbar-expand .offcanvas{
  z-index: 9999;
  background-color: rgba($color: #feab6b, $alpha: 0.6);
  box-shadow: inset 0px 0px 2px #ff5800;
  transition: all .5s ease-in-out
}
.coins-box{
  width: 80px;
  font-size: 12px;
  border-radius: 15px;
  min-width: 10ch;
  background: rgb(81 81 81 / 80%) ;
  color: white;
  padding: 0.7rem 0;
}

.navbar-collapse.show {
  transform: translateY(0); 
}

.navbar-collapse:not(.show) {
  transform: translateY(-10000px);
  height: 0;
}
.navbar-toggler{
  box-shadow: 0;
}
.btn-close {
  background-color: #333;
  border: none; 
  opacity: 0.8; 
  transition: background-color 0.3s ease, opacity 0.3s ease; 
}

.btn-close:hover {
  background-color: #555; 
  opacity: 1; 
}
@media (max-width: 998px) {
  .w-80{
    width: 65% !important;
  }
  .w-20{
    width: 35% !important;
  }
  .container.d-flex.justify-content-between.align-items-center.w-100{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .w-40{
    width: 45% !important;
  }
  .navbar-brand{
    width: 10% !important;
  }
  .navbar .nav-link{
    font-size: 14px !important;
  }
}
@media (max-width: 758px) {
  .w-80{
    width: 100% !important;
  }
  .container-fluid{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .nav-top-con .container{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .nav-top-con{
    flex-direction: column !important;
  }
  .navbar-expand .navbar-toggler{
    display: block;
  }
  .text-lg{
    font-size: 14px !important;
  }
}
@media (max-width: 458px) {
  .offcanvas {
    width: 100%;
  }
}
</style>