<template>
    <div>
      Processing...
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        userProfile: null
      };
    },
    created() {
      const code = this.$route.query.code;
      if (code) {
        this.handleLoginCallback(code);
      }
    },
    methods: {
      async handleLoginCallback(code) {
        const accessToken = await this.getAccessToken(code);
        if (accessToken) {
          this.userProfile = await this.getUserProfile(accessToken);
        }
      },
      async getAccessToken(code) {
        const data = {
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: 'https://termgame.goldenbug.co/auth/line',
          client_id: '2004893434',
          client_secret: '31a7c86cb04deab88dacf9ffd1a16be3'
        };
  
        try {
          const response = await axios.post('https://api.line.me/oauth2/v2.1/token', data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          return response.data.access_token;
        } catch (error) {
          console.error('Error obtaining access token:', error);
        }
      },
      async getUserProfile(accessToken) {
        try {
          const response = await axios.get('https://api.line.me/v2/profile', {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          // console.log(response.data);
          this.register(response.data)
          return response.data;
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      },
      generateRandom10Digit() {
        const random7Digits = Math.floor(Math.random() * 10000000).toString().padStart(7, '0');
        return '000' + random7Digits;
      },
      register(userData) {
        let data = new URLSearchParams();
        // console.log(this.user)
        data.append('username', userData.userId);
        data.append('password', userData.userId);
        data.append('full_name', userData.displayName);
        data.append('token_line', userData.userId);
        data.append('mobile', this.generateRandom10Digit());
        data.append('email', `${userData.userId}@line.com`);

        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.post(`${this.$store.state.defaultStore.host_api}/member/register`, data)
          .then(response => {
            // Handle the response data using a function
            if (!response.data.success) {
              if (response.data.datas) {
                var htmlString = '<ul style="list-style: none">' + response.data.datas.map(function(item) {
                    return '<li>' + item + '</li>';
                }).join('') + '</ul>';
              }
            }
            this.login(userData.userId, userData.userId)

          })
          .catch(error => {
            console.error(error);
          });
      },
      login(username, password) {
        let data = new URLSearchParams();
        data.append('username', username);
        data.append('password', password);

        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.post(`${this.$store.state.defaultStore.host_api}/auth/login`, data)
          .then(response => {
            if (!response.data.success) {
              return this.$swal({
                icon: "warning",
                title: 'ข้อมูลไม่ถูกต้อง',
                type: 'warning',
              });
            }
            // Handle the response data using a function
            const TOKEN = response.data.datas.tokenData ?? false;
            if (!TOKEN) {
              console.log('error');
              return false;
            }
            this.$store.dispatch('loginSuccess', TOKEN.token);
            window.localStorage.setItem('jcr_token', TOKEN.token)

            const selectedItem = JSON.parse(window.localStorage.getItem('selectedItem'));
            if (selectedItem && selectedItem.length) {
              const game_id = selectedItem[0].game_id._id;
              return window.location.href = `/game-topup/${game_id}`;
            }
            return window.location.href = `/`;
          // window.location.href = `/${this.getSetting.member_landing ? this.getSetting.member_landing : ''}`; 
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  };
  </script>  