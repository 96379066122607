import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import axios from 'axios';
import defaultSetting from '../cms/default.json';
// const API_URL = process.env.VUE_APP_API_URL;
const API_URL = "https://termgame-api.goldenbug.co/api";

export default {
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    mockData: null,
    selectedItem: [],
    host_api: 'https://termgame-api.goldenbug.co/api',
    isLoggedIn: window.localStorage.getItem('jcr_token') ? true : false,
    memberProfile: null,
    token: window.localStorage.getItem('jcr_token') || '',
    setting: defaultSetting,
    article: [],
    settingCMS: null
  },
  mutations: {
    SET_ARTICLE(state, article) {
      state.article = article;
    },
    SET_SETTING_DEFAULT(state, setting) {
      state.setting = JSON.parse(setting.json);
      state.settingCMS = setting;
    },
    SET_PROFILE(state, profile) {
      state.memberProfile = profile;
    },
    DESTROY_TOKEN(state) {
      window.localStorage.removeItem('jcr_token');
      state.token = false;
      state.memberProfile = null;
      state.isLoggedIn = false;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      state.isLoggedIn = true;
    },
    INITIAL_CART(state) {
      state.selectedItem = [];
    },
    addToSelectedItem(state, item) {
      state.selectedItem.push(item);
    },
    removeFromSelectedItem(state, item) {
      const index = state.selectedItem.findIndex(x => x._id === item._id);
      if (index !== -1) {
        state.selectedItem.splice(index, 1);
      }
    },
    updateItemQty(state, { itemId, newQty }) {
      const item = state.selectedItem.find(item => item._id === itemId);
      if (item) {
        item.quantity = newQty;
      }

      if (newQty == 0) {
        const index = state.selectedItem.findIndex(x => x._id === itemId);
        if (index !== -1) {
          state.selectedItem.splice(index, 1);
        }
      }
    },
    setMockData(state, payload) {
      state.mockData = payload;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    setFilteredMockData(state, data) {
      state.filteredMockData = data;
    },
    UPDATE_SELECTED_ITEMS(state, items) {
      state.selectedItem = items;
    },
  },
  actions: {
    logOut({ commit }) {
      commit('DESTROY_TOKEN');
    },
    loginSuccess({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    InitialCart({ commit }) {
      commit('INITIAL_CART');
    },
    selectItem({ commit, state }, item) {
      if (state.selectedItem.find(x => x._id === item._id)) {
        commit('removeFromSelectedItem', item);
      } else {
        if (state.selectedItem.length) {
          state.selectedItem = []
        }
        commit('addToSelectedItem', item);
      }
    },
    removeItemFromCart({ commit }, item) {
      commit('removeFromSelectedItem', item);
    },
    updateItemQty({ commit }, { itemId, newQty }) {
      commit('updateItemQty', { itemId, newQty });
    },
    fetchMockDataByCategory({ state, commit }, category) {
      const data = state.mockData.filter(item => item.category === category);
      commit('setFilteredMockData', data);
    },
    fetchMockData({ commit }) {
      let itemNames = [
        "100 Gems",
        "200 Gems",
        "300 Gems",
        "400 Gems",
        "500 Gems",
        "600 Gems",
        "700 Gems",
        "800 Gems",
        "900 Gems",
        "1,000 Gems",
    ];
    
    let mockDataList = [];
    
    for (let i = 0; i < 7; i++) {
      i = i.toString().padStart(2, '0');
      let mockData = {
        "id": `60e65e8f2d0975f8bb4b7e6a${i}`,
        "game_code": `60e65e8f2d0975f8bb4b7e6b${i}`,
        "name": itemNames[i % itemNames.length], // This will cycle through the itemNames array
        "price": 100 * (parseInt(i)+1),
        "cost": 1450 + i,
        "profit": 50 + i,
        "game_point": 1000 + i,
        "img_url": `${i}.png`,
        "show_only": i % 2 === 0, // alternates between true and false
        "status_active": i % 2 === 1, // alternates between false and true
        "category": i % 2 // alternates between 0 and 1
      };
      mockDataList.push(mockData);
    }
      commit('setMockData', mockDataList);
    },
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    async getProfile({ commit, state }) {
      try {
        axios.defaults.headers.common['Authorization'] = state.token;
        const response = await axios.post(`${API_URL}/auth/getMyAccount`);
        if (response.data.success) {
          // if (window.location.pathname != '/verify' && !response.data.datas.verdify_mobile) {
          //   return window.location.href = '/verify';
          // }
          const selectedItem = JSON.parse(window.localStorage.getItem('selectedItem'));
          if (selectedItem) {
            state.selectedItem = selectedItem;
            window.localStorage.removeItem('selectedItem');
          }
          commit('SET_PROFILE', response.data.datas);
        } else {
          commit('DESTROY_TOKEN');
        }
        return response;
      } catch (error) {
        console.error('An error occurred while fetching profile: ', error);
        throw error;
      }
    },
    async updateProfile({ commit, state }, user) {
      try {
        axios.defaults.headers.common['Authorization'] = state.token;
        user.member_id = user._id;
        delete user.password;
        const response = await axios.post(`${API_URL}/member/editMember`, user);
        if (response.data.success) {
          commit('SET_PROFILE', user);
        }
        return response;
      } catch (error) {
        console.error('An error occurred while update profile: ', error);
        throw error;
      }
    },
    async setProfile({ commit }, profile) {
      try {
        commit('SET_PROFILE', profile);
      } catch (error) {
        console.log('An error occurred while fetching orders: ', error);
      }
    },
    async getSettingCMS({ commit, state }) {
      try {
        const response = await axios.get(`${API_URL}/settingCMS/get`);
        commit('SET_SETTING_DEFAULT', response.data.datas);
      } catch (error) {
        console.log('An error occurred while fetching orders: ', error);
      }
    },
    async fetchArticle({ commit, state }, param) {
      try {
        const response = await axios.post(`${API_URL}/article/getList`, param);
        commit('SET_ARTICLE', response.data.datas);
      } catch (error) {
        console.log('An error occurred while fetching orders: ', error);
      }
    },
    updateSelectedItems({ commit }, items) {
      commit('UPDATE_SELECTED_ITEMS', items);
    },
  },
  getters: {
    getArticle: state => {
      return state.article;
    },
    getSetting: state => {
      return state.setting;
    },
    getSettingCMSAll: state => {
      return state.settingCMS;
    },
    getMemberProfile: state => {
      return state.memberProfile;
    },
    isLoggedIn: state => {
      return state.isLoggedIn;
    },
    checkCart: state => item => {
      return state.selectedItem.some(x => x._id === item._id);
    },
    checkCartItem: state => item => {
      return state.selectedItem.find(x => x._id === item._id);
    },
    getSelectedItem: state => {
      return state.selectedItem;
    },
    totalPrice: (state, getters) => {
      const memberProfile = getters.getMemberProfile;
      const margin = memberProfile && memberProfile.margin != null && memberProfile.margin > 0 ? memberProfile.margin : 0; // ใช้ margin จาก getMemberProfile
    
      const total = state.selectedItem.reduce((total, item) => {
        const price = margin > 0 ? item.price * (1 - margin / 100) : item.price;
        return total + price * (item.quantity || 1);
      }, 0);
    
      return margin > 0 ? total.toFixed(2) : total; // จัดรูปแบบเป็นทศนิยม 2 ตำแหน่งถ้ามี margin
    },
    itemsByCategory: state => category => {
      return state.mockData.filter(item => item.category === category);
    },
    getSelectedItems: state => {
      return state.selectedItem;
    },
    getFilteredMockData: state => state.filteredMockData,
  }
}