<template>
  <navbar :is-blur="true" :dark-mode="true" />
      <div class="container-fluid px-0 mt-8">
        <div class="container mt-4">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="title-highlight">
                <h4 class="text-white">{{ $t('menu.idPass') }}</h4>
              </div>
            </div>
          </div>
          <div class="container px-0">
            <div class="d-flex justify-content-between gap-2 flex-wrap flex-md-nowrap home-tab">
              <div class="d-flex justify-content-start gap-3 overflow-scroll text-nowrap flex-nowrap w-100">
                <div class="" v-for="(item, index) in postStatusList" :key="index">
                  <button class="btn fs-6 mobile-button" :class="postStatusNormal == item.status ? 'btn-danger' : 'btn-outline-danger'" @click="postStatusNormal = item.status">
                    <span><i v-if="item.icon" :class="item.icon"></i> {{ $t(`homepage.${item.text}`) }}</span>
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-4 mb-4">
                <input class="form-control p-2 " type="text" v-model="searchInputNormal" :placeholder="$t('homepage.searchGame')"/>
              </div>
            </div>
          </div>
        
          <div class="container px-4" v-if="gameFilterNormal && gameFilterNormal.length && !isMobile">
            <div class="row justify-content-start">
                <div class="col-12 mb-3">
                    <div class="row">
                        <div v-for="(game, index) in gameFilterNormal" :key="index" class="col-lg-2 col-md-3 col-6 px-2">
                            <div class="card card-game d-flex flex-column position-relative cursor-pointer zoom-product" @click="goToBuy(game._id)">
                              <span class="badge badge-success animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0" v-if="game.post_status && game.post_status === 1">new</span>
                              <span class="badge badge-danger animate__animated animate__pulse animate__infinite animate__delay-3s position-absolute end-0" v-if="game.post_status && game.post_status === 2">hot</span>
                                <!-- <img src="../../assets/img/badge/เติมเกม24ชั่วโมง.jpg" class="game-icon img-fluid w-100 h-100" :alt="`รับเติมเกม ${ game.name }`"> -->
                                <img v-lazy="game.icon_url" class="game-icon img-fluid w-100 h-100" :alt="`รับเติมเกม ${ game.name }`">
                                <div class="card-body d-flex flex-column flex-grow-1 p-0">
                                  <h3 class="title text-sm text-white text-center">{{ game.name }}</h3>
                                  <div class="text-center w-100">
                                      <router-link data-scroll :to="`/game-topup/${game._id}`" class="btn btn-sm btn-danger w-100">{{ getSetting.btn.select.text || 'เลือก' }}</router-link>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- If no games -->
        <div v-if="gameFilterNormal.length == 0">
          <div class="container px-4 mb-4">
              <div class="row justify-content-center">
                  <div class="col text-center px-2">
                      <div class="card blur-dark-fade p-5">
                          <h4>{{ $t('homepage.notFoundGame') }}</h4>
                      </div>
                  </div>
              </div>
          </div>
        </div> 
        </div>
      </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";


export default {
  name: "GameIdPass",
  components: {
    Navbar,
    AppFooter,

  },
  data() {
    return {
      searchInputNormal: '',
      postStatusList: [
        {
          status: '',
          text: "all",
          icon: 'fas fa-th',
        },
        {
          status: 1,
          text: "new",
          icon: "fas fa-meteor",
        },
        {
          status: 2,
          text: "hot",
          icon: 'fas fa-fire',
        },
      ],
      postStatusNormal: '',
      itemsPerPage: 6,
      displayedItems: 6
    }
  },
  async created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    await this.fetchGames();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  computed: {
    ...mapGetters(['games', 'gamesTopupNormal',  'getSetting', 'isLoggedIn']),
    gameFilterNormal() {
      let filteredGames = this.gamesTopupNormal.filter(game => game.product_type == 1);
      if (this.searchInputNormal) {
        if (this.postStatusNormal) {
          return filteredGames.filter(game => {
            return game.name.toLowerCase().includes(this.searchInputNormal.toLowerCase())
                  && game.post_status == this.postStatusNormal;
          });
        }
        return filteredGames.filter(game => {
          return game.name.toLowerCase().includes(this.searchInputNormal.toLowerCase());
        });
      }
      if (this.postStatusNormal) {
        return filteredGames.filter(game => {
          return game.post_status == this.postStatusNormal;
        });
      }
      return filteredGames;
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(['fetchGames']),
    loadMoreNormal() {
      this.displayedItems += this.itemsPerPage
      if (this.displayedItems > this.gameFilterNormal.length) {
        this.displayedItems = this.gameFilterNormal.length
      }
    }
  }
};
</script>

<style lang="scss">
  .home-tab {
    .btn-outline-danger {
      background-color: #2c2c2c;
      border: none;
      &:hover {
        background-color: #2c2c2c !important;
        transform: unset !important;
        opacity: unset !important;
      }
    }
    padding: 0px 24px;
  }
  .game-icon{
    width: 100%;
    height: 200px; /* กำหนดความสูงของรูปภาพ */
    object-fit: cover;
  }
  .zoom-product {
    overflow: hidden;
    position: relative;
    transition: transform 0.5s ease;
    }
  
    .zoom-product:before,
    .zoom-product:after {
      content: "";
      position: absolute;
      top: -50%;
      left: -100%;
      width: 60%;
      height: 200%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      transform: rotate(25deg);
      z-index: 1;
    }
  
    .zoom-product:before {
      transition: all 1.2s ease;
    }
  
    .zoom-product:after {
      transition: all 1.2s ease;
      transition-delay: 0.4s;
    }
  
    .zoom-product:hover:before,
    .zoom-product:hover:after {
      left: 130%;
    }
  
    .zoom-product:hover {
      transform: scale(1.03);
    }
  
    .zoom-product .btn {
      position: relative;
      z-index: 2;
      transition: all 0.3s ease;
    }
  
    .zoom-product:hover .btn {
      background-color: #dc3545;
      color: white;
    }
</style>