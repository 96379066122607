<template>
    <div>
      Processing...
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        userProfile: null
      };
    },
    created() {
      const code = this.$route.query.code;
      if (code) {
        this.handleLoginCallback(code);
      }
    },
    methods: {
      async handleLoginCallback(code) {
        const accessToken = await this.getAccessToken(code);
        if (accessToken) {
          this.userProfile = await this.getUserProfile(accessToken);
        }
      },
      async getAccessToken(code) {
        const data = {
          code: code,
          grant_type: 'authorization_code',
          redirect_uri: 'https://termgame.goldenbug.co/auth/google',
          client_id: '1055649956934-4cqqoel3sa26m2p7kpghcqhpedfvkp1m.apps.googleusercontent.com',
          client_secret: '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'
        };
  
        try {
          const response = await axios.post('https://oauth2.googleapis.com/token', data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          return response.data.access_token;
        } catch (error) {
          console.error('Error obtaining access token:', error);
        }
      },
      async getUserProfile(accessToken) {
        try {
          const response = await axios.get('https://www.googleapis.com/oauth2/v2/userinfo', {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          // console.log(response.data);
          this.register(response.data);
          return response.data;
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      },
      generateRandom10Digit() {
        const random7Digits = Math.floor(Math.random() * 10000000).toString().padStart(7, '0');
        return '000' + random7Digits;
      },
      register(userData) {
        let data = new URLSearchParams();
        const username = `gg${userData.id}`;
        data.append('username', username);
        data.append('password', userData.id);
        data.append('full_name', userData.email);
        data.append('token_google', userData.id);
        data.append('mobile', this.generateRandom10Digit());
        data.append('email', `${userData.email}`);
        data.append('picture', `${userData.picture}`);

        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.post(`${this.$store.state.defaultStore.host_api}/member/register`, data)
        .then(response => {
          // Handle the response data using a function
          if (!response.data.success) {
            if (response.data.datas) {
              var htmlString = '<ul style="list-style: none">' + response.data.datas.map(function(item) {
                  return '<li>' + item + '</li>';
              }).join('') + '</ul>';
            }
          }
          this.login(username, userData.id)
        })
        .catch(error => {
          console.error(error);
        });
      },
      login(username, password) {
        let data = new URLSearchParams();
        data.append('username', username);
        data.append('password', password);

        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.post(`${this.$store.state.defaultStore.host_api}/auth/login`, data)
        .then(response => {
            if (!response.data.success) {
              return this.$swal({
                icon: "warning",
                title: response.data.message || 'ข้อมูลไม่ถูกต้อง',
                type: 'warning',
              });
            }
            // Handle the response data using a function
            const TOKEN = response.data.datas.tokenData ?? false;
            if (!TOKEN) {
              console.log('error');
              return false;
            }
            this.$store.dispatch('loginSuccess', TOKEN.token);
            window.localStorage.setItem('jcr_token', TOKEN.token)

            const selectedItem = JSON.parse(window.localStorage.getItem('selectedItem'));
            if (selectedItem && selectedItem.length) {
              const game_id = selectedItem[0].game_id._id;
              return window.location.href = `/game-topup/${game_id}`;
            }
            return window.location.href = `/`;
            // window.location.href = `/${this.getSetting.member_landing ? this.getSetting.member_landing : ''}`;
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  };
  </script>  